<template>
    <div class="flex items-center h-10 border border-neutral-200 rounded-3xl justify-between w-32">
        <button type="button" :disabled="disableMinus" @click="editNumber('minus')"
            class="h-full w-10 flex-shrink-0 flex items-center justify-center">
            <Icon name="tabler:minus" size="16" :class="{
                'text-neutral-200 dark:text-white': disableMinus,
                'text-secondary-800 dark:text-secondary-400': !disableMinus
            }" />
        </button>
        <span class="w-full text-center dark:text-white text-sm font-medium">{{ modelValue }}</span>
        <button type="button" @click="editNumber('plus')" class="h-full w-10 flex-shrink-0 flex items-center justify-center ">
            <Icon name="tabler:plus" size="16" class="text-secondary-800 dark:text-secondary-400" />
        </button>
    </div>
</template>
<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0
    },
    min:{
        type:Number,
        default:0
    }
})
const emit = defineEmits(['update:modelValue'])

const disableMinus = computed(() => {
    return props.modelValue <= props.min;
})
const editNumber = (type: string) => {
    switch (type) {
        case 'plus':
            emit('update:modelValue', props.modelValue + 1)
            break;
        case 'minus':
            if (!disableMinus.value) {
                emit('update:modelValue', props.modelValue - 1)
            }
            break;
    }
}
</script>