<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useBookingStore } from "~/stores/booking";

const { saveData } = useBookingStore();
const { form } = storeToRefs(useBookingStore());
const validateChildren = ref(false);
const emits = defineEmits(["close"]);
const SelectPerson = (close: any) => {
  if (form.value.ageChildren.filter((item: any) => item === "").length) {
    validateChildren.value = true;
  } else {
    validateChildren.value = false;
    emits("close");
  }
  saveData();
};
watch(
  () => form.value.children,
  () => {
    if (form.value.ageChildren.length >= form.value.children) {
      form.value.ageChildren = JSON.parse(
        JSON.stringify(form.value.ageChildren.slice(0, form.value.children))
      );
    } else {
      for (
        let i = form.value.ageChildren.length;
        i < form.value.children;
        i++
      ) {
        form.value.ageChildren.push("");
      }
    }
    validateChildren.value = false;
  }
);
</script>
<template>
  <div class="space-y-4 lg:space-y-6">
    <div class="flex items-center justify-between md:space-x-0 space-x-4">
      <div class="flex flex-col dark:text-white">
        <span class="font-semibold capitalize">{{ $t("adults") }}</span>
        <span class="text-xs capitalize">18+ {{$t('year_old')}} </span>
      </div>
      <ElementsInputNumber
        :model-value="form.adults"
        :min="1"
        @update:model-value="(v:any) => (form.adults = v)"
      />
    </div>
    <div>
      <div class="flex items-center justify-between md:space-x-0 space-x-4">
        <div class="flex flex-col dark:text-white">
          <span class="font-semibold capitalize">{{ $t("children") }}</span>
          <span class="text-xs capitalize">0-17 {{$t('year_old')}} </span>
        </div>
        <ElementsInputNumber
          :model-value="form.children"
          @update:model-value="(v:any) => (form.children = v)"
        />
      </div>
      <div v-if="form.children > 0" class="text-left">
        <div class="grid grid-cols-2 gap-4 mt-4 mb-4">
          <select
            v-for="index in form.children"
            v-model="form.ageChildren[index - 1]"
            :class="{
              'border-red-500':
                form.ageChildren[index - 1] === '' && validateChildren,
            }"
            class="h-9 w-auto px-3 rounded-3xl border border-neutral-200 flex items-center text-sm font-normal outline-none focus:outline-none focus:ring-0 focus:border-neutral-200"
          >
            <option value="" disabled>
              {{ $t("age_needed") }}
            </option>
            <option :value="i - 1" v-for="i in 18">
              {{ i - 1 }} {{ $t("year_old") }}
            </option>
          </select>
        </div>
        <span class="text-xs font-normal text-neutral-500 dark:text-neutral-200">
          {{ $t("age_message") }}
        </span>
      </div>
    </div>
    <div class="flex items-center justify-between md:space-x-0 space-x-4">
      <span class="font-semibold capitalize dark:text-white">{{ $t("rooms") }}</span>
      <ElementsInputNumber
        :model-value="form.rooms"
        :min="1"
        @update:model-value="(v:any) => (form.rooms = v)"
      />
    </div>
    <button
   
      type="button"
      @click="SelectPerson"
      class="h-10 w-full rounded-3xl border border-neutral-200 text-neutral-800 dark:text-neutral-50 dark:hover:border-secondary-800 dark:hover:bg-secondary-80 hover:border-secondary-50 hover:bg-secondary-50 font-semibold flex items-center justify-center"
    >
      {{ $t("done") }}
    </button>
  </div>
</template>
